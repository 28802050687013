<template>
  <div class="page-profile">
    <div class="container container--lg">
      <div class="page-profile__head">

        <h1 class="page-title">Помощь для себя</h1>
        <p class="page-text">Укажите данные о себе</p>

      </div>
      <div class="row row--lg">
        <div class="col-12 col-md-4 col--lg">

          <NavSteps
            :list="stepList"
            :active="tab"
            @choose="changeTab"
          />

        </div>
        <div class="col-12 col-md-8 col--lg">
          <template v-if="tab === 0">

            <DataRecipient
              :valid.sync="steps.general.valid"
              :data.sync="steps.general.data"
            />

          </template>
          <template v-else-if="tab === 1">

            <DocumentsPerson
              :valid.sync="steps.documents.valid"
              :data.sync="steps.documents.data"
            />

          </template>
          <template v-else-if="tab === 2">

            <ServicesNeed
              :valid.sync="steps.help.valid"
              :data.sync="steps.help.data"
            />

          </template>
          <template v-else-if="tab === 3">

            <PaymentMethod
              :valid.sync="steps.payment.valid"
              :active="paymentMethod"
              :list="PaymentList"
              @change="choosePayment"
            />

            <template v-if="paymentMethod === 'Банковская карта'">

              <PaymentCard
                :data.sync="steps.payment.data"
              />

            </template>
          </template>
          <div class="page-profile__btn-gp">

            <template v-if="tab === 0">

              <router-link
                :to="{ name: 'ASSISTANCE_NEED_SELECT'}"
                class="btn btn--border"
              >Назад</router-link>

            </template>
            <template v-if="tab > 0">

              <button
                type="button"
                class="btn btn--border"
                @click="changeTab(--tab)"
              >Назад</button>

            </template>
            <template v-if="tab < 3">

              <button
                type="button"
                class="btn btn--prime"
                :disabled="!valid"
                @click="changeTab(++tab)"
              >Далее</button>

            </template>
            <template v-if="tab === 3">

              <button
                type="button"
                class="btn btn--prime"
                :disabled="loading || !validAllForms"
                @click="submit"
              >

                <template v-if="loading">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </template>

                <template v-else>Завершить</template>

              </button>

            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import NavSteps from '@component/Module/NavSteps';

import DataRecipient from '@component/Page/Profile/DataRecipient';
import DocumentsPerson from '@component/Page/Profile/DocumentsPerson';
import ServicesNeed from '@component/Page/Profile/ServicesNeed';
import PaymentMethod from '@component/Page/Profile/PaymentMethod';
import PaymentCard from '@component/Page/Profile/PaymentCard';
import confirmLeave from "@mixin/confirmLeave";

import { mapState } from 'vuex';
import stepList from "@mixin/Register/stepList";
import validation from "@mixin/Register/validation";

export default {
  components: {
    NavSteps,
    DataRecipient,
    DocumentsPerson,
    ServicesNeed,
    PaymentMethod,
    PaymentCard,
  },
  mixins: [confirmLeave, stepList, validation],
  computed: {
    ...mapState('user', ['user']),

    valid() {
      return this.steps[this.tabs[this.tab]].valid;
    },
  },
  data() {
    return {
      tab: 0,
      currentForm: false,
      loading: false,
      paymentMethod: '',
      PaymentList: [
        {
          value: 'Банковская карта',
          label: 'Банковская карта',
          icon: require('@img/icons/visa.svg'),
        },
        {
          value: 'Наличные',
          label: 'Наличные',
          icon: require('@img/icons/wallet.svg'),
        }
      ],
      country: {
        country: null,
        city: null,
        district: null,
        region: null
      },
      tabs: ['general', 'documents', 'help', 'payment'],
      testData: {
        general: {
          valid: false,
          num: 0,
          title: 'Общие сведения о себе',
          data: {
            photo: null,
            first_name: 'Дядя',
            last_name: 'Петя',
            gender: 'MALE',
            birthday: '14.02.1995',
            weight: '150',
            reason: 'hz',
            address: 'Narnia',
            commentary: '',
            email: ''
          },
          key: 'general'
        },
        documents: {
          valid: false,
          num: 1,
          title: 'Мои документы',
          data: {
            serial: '1001',
            number: '101010',
            files: []
          },
          key: 'documents'
        },
        help: {
          valid: true,
          num: 2,
          title: 'Какая помощь требуется',
          key: 'help',
          data: {
            services: []
          }
        },
        payment: {
          valid: false,
          num: 3,
          title: 'Способ оплаты',
          data: {
            number: '',
            estimate: '',
            cvv: ''
          },
          key: 'payment'
        },
      },
      steps: {
        general: {
          valid: false,
          num: 0,
          title: 'Общие сведения о себе',
          data: {
            photo: null,
            first_name: '',
            last_name: '',
            gender: '',
            birthday: '',
            weight: '',
            reason: '',
            commentary: ''
          },
          key: 'general'
        },
        documents: {
          valid: false,
          num: 1,
          title: 'Мои документы',
          data: {
            serial: '',
            number: '',
            files: []
          },
          key: 'documents'
        },
        help: {
          valid: false,
          num: 2,
          title: 'Какая помощь требуется',
          key: 'help',
          data: {
            services: []
          }
        },
        payment: {
          valid: false,
          num: 3,
          title: 'Способ оплаты',
          data: {
            number: '',
            estimate: '',
            cvv: ''
          },
          key: 'payment'
        },
      },
    };
  },
  methods: {
    submitForm(formData, key) {
      this.steps[key].data = formData;
    },
    changeTab(tab) {
      this.tab = tab;
      this.currentForm = this.steps[this.tabs[tab]].valid;
    },
    choosePayment(value) {
      this.paymentMethod = value;
    },
    async submit() {
      this.loading = true;
      try {
        const sendUser = await RequestManager.Register.sendUser({
          general: {
            ...this.steps.general.data,
            is_registered: true,
            need_help: true
          },
          documents: this.steps.documents.data
        });
        if (sendUser) {
          await this.$store.commit('user/setUser', sendUser);
          this.$router.push({
            name: this.$routeName.ASSISTANCE_NEED_APPLICATIONS
          });
        }

      }
      catch(err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    this.country = this.$route.query;

    if (this.$route.query._debug === '1') {
      this.$set(this, 'steps', this.testData);
    }

  }
};

</script>

<style lang="scss" scoped>

// page-profile
.page-profile {
  padding: 80px 0 30px;
  .page-profile__head {
    margin-bottom: 30px;
    @include respond-to('sm') {
      text-align: center;
    }
  }
  .page-profile__btn-gp {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    .btn {
      width: 100%;
      max-width: 343px;
      margin-bottom: 20px;
    }
  }
  @include respond-to('sm') {
    padding: 100px 0 30px;
    .page-profile__head {
      margin-bottom: 40px;
    }
    .page-profile__btn-gp {
      flex-direction: row;
      justify-content: space-between;
      margin-top: 30px;
      .btn {
        width: 48%;
        max-width: 343px;
        margin-bottom: 0px;
      }
    }
  }
  @include respond-to('md') {
    padding: 120px 0 30px;
    .page-profile__head {
      margin-bottom: 50px;
    }
  }
}

</style>
